li {
    h3 {
        font-family: 'Amatic SC', cursive;
        font-size: 2.5rem;
        letter-spacing: .5rem;
        font-weight: 900;
        border-radius: 10px;
        border: 1.5px dotted #FF0080;
        padding: 10px;
        margin: 5px;
        width: max-content;
    }
}

.similar-artists {
    @media (max-width: 500px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.error-state {
    font-family: 'Oswald', sans-serif;
    font-size: 7rem;
    letter-spacing: .5rem;
    text-align: center;
    @media (max-width: 565px) {
        font-size: 5rem;
    }
}

.no-similar-artists {
    font-family: 'Oswald', sans-serif;
    font-size: 2rem;
    letter-spacing: .5rem;
}

.results-flex {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: 'Amatic SC', cursive;
    padding: 0 200px;
    @media (max-width: 525px) {
        flex-direction: column;
        padding: 0;
        }

        .artist-details {
            .artist-details-name {
                font-family: 'Amatic SC', cursive;
                font-size: 5rem;
                letter-spacing: .5rem;
                font-weight: 900;
                border: 5px solid #FF0080;
                padding: 10px;
                margin: 5px;
                }
            @media (max-width: 525px) {
                margin-bottom: 25px;
            }
        }

        .sim-artist-container {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
        }

        .tag-flex-one {
            display: flex;
            flex-wrap: wrap;
        }

        .tag-flex-two {
            display: flex;
            flex-wrap: wrap;
        }

        p {
            font-size: 2.5rem;
            padding: 5px;
            margin: 0;
            position: relative;
        }

        .after::after {
            content: " ";
            width: 5px;
            height: 5px;
            rotate: 45deg;
            display: inline-block;
            background: #FF0080;
            top: 50%;
            right: 45%;
            position: absolute;
        }
}