header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 60px 0;

    h1 {
        font-family: 'Oswald', sans-serif;
        font-size: 10rem;
        letter-spacing: 1.5rem;
        text-transform: uppercase;
        padding: 20px;
        border: 5px solid #FF0080;
        text-align: center;
        @media (max-width: 1073px) {
            font-size: 7rem;
            letter-spacing: 1.2rem;
        }
        @media (max-width: 680px) {
            font-size: 5.5rem;
            letter-spacing: 1rem;
        }
    }

    h3 {
        font-family: 'Oswald', sans-serif;
        font-size: 3.3rem;
        letter-spacing: 1.5rem;
        padding-bottom: 25px;
        padding-top: 30px;
        text-align: center;
        @media (max-width: 1073px) {
                font-size: 2rem;
                letter-spacing: 1rem;
            }
            @media (max-width: 680px) {
                font-size: 1.7rem;
                letter-spacing: .7rem;
            }
    }
}





