footer {
    margin-top: auto;
    width: 100%;
    p {
        font-family: 'Oswald', sans-serif;
        font-size: 1.3rem;
        letter-spacing: .5rem;
        text-align: right;
        @media (max-width: 525px) {
                margin-top: 50px;
            }
    }
}