form {
    font-family: 'Oswald', sans-serif;
    font-size: 2rem;
    letter-spacing: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    @media (max-width: 500px) {
        flex-direction: column;
        }

    .search-label {
        font-family: 'Oswald', sans-serif;
        letter-spacing: .5rem;
        margin: 15px;
        padding: 2px;
        text-align: center;
    }
    
    .search-input {
        border: none;
        border-bottom: 2px solid black;
        outline: none;
    }

    .search-input:focus {
        outline: none !important;
    }
    
    .submit-button {
        border: none;
        background: none;
        border: 2px solid #FF0080;
        color: black;
        text-transform: uppercase;
        letter-spacing: .5rem;
        list-style: none;
        font-weight: 700;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        margin-left: 10px;
        padding: 5px 20px;
        @media (max-width: 500px) {
            margin: 25px 0;
            }
      }

    }